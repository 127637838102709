.product-card {
    width: calc(20% - 25px);
    margin: 10px;
    border-radius: 10px;
    background-color: $c-white;
    box-shadow: $s-shadow;
    overflow: hidden;
    cursor: pointer;

    &__image {
        width: 100%;
        height: 200px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__content {
        width: 100%;
        padding: 10px;

        p {
            margin: 5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }    
}
