.account-address {
    padding: 25px;
    width: 100%;

    &__header {
        display: flex;
        align-items: center;

        p {
            margin-left: 15px;
        }
    }

    &__input-wrapper {
        margin-top: 30px;

        &:first-child {
            margin: 0;
        }
    }

    &__button-wrapper {
        margin-top: 35px;
    }
}