.category-picker {
    position: relative;

    &__carousel {
        width: 100%;
        max-width: 1260px;
        padding: 10px 20px;
        margin: 20px auto;
        border-radius: 5px;
        background-color: $c-white;
        box-shadow: $s-shadow;
        display: flex;
        align-items: center;
    }

    &__wrapper {
        width: 100px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__image {
        width: 40px;
        height: 40px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__link {
        text-decoration: none;
        text-align: center;

        p {
            margin-top: 8px;
        }
    }

    .react-multi-carousel-track {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .react-multiple-carousel {
        &__arrow {
            height: 100%;
            background-color: white;

            &:before {
                color: $c-black;
            }

            &--right {
                right: 0;
            }
            &--left {
                left: 0;
            }
        }
    }
}
