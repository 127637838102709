.footer {
    padding: 0 25px;

    &__section {
        padding: 25px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &:not(:last-child) {
            border-bottom: 1px solid rgba($c-black, 0.2);
        }
    }

    &__logo {
        width: 80px;
        margin-right: 10px;
    }

    &__content {
        padding: 10px;

        p {
            margin-bottom: 10px;
        }

        img {
            margin-right: 15px;
            width: 150px;
        }

        &--logo {
            display: flex;

            img {
                width: 100%;
            }
        }

        &--payment {
            img {
                width: 200px;
            }
        }
    }

    &__about {
        display: flex;
        flex-direction: column;
        margin-left: 100px;
    }

    &__app-download {
        display: flex;
    }

    &__list {
        margin: 0;
        padding: 0;
        display: flex;
    }

    &__list-item {
        padding: 0 25px;
        list-style-type: none;
        cursor: pointer;
        &:first-child {
            padding-left: 0;
        }

        &:not(:last-child) {
            border-right: 1px solid $c-black;
        }

        a {
            text-decoration: none;
        }
    }
}
