.account-settings {
    padding: 25px;

    &__wrapper{
        display: flex;
    }

    &__page {
        margin: 0 20px;
        width: 100%;
        min-width: 400px;
        margin-top: 20px;
    }

    &__tabs {
        display: flex;
        flex-direction: column;
    }

    &__tab {
        width: 270px;
        padding: 20px;
        font-size: 18px;
        font-weight: 400;
        border: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $c-white;
        border-bottom: 1px solid $c-layout-bg;

        &--active {
            background-color: rgba($c-secondary, 0.1);
        }
    }
}
