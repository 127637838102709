@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import "normalize.css";
@import "react-responsive-modal/styles.css";
@import "react-responsive-carousel/lib/styles/carousel.min.css";
@import "styles/colors.scss";
@import "styles/m-touch.scss";

/* Components */
@import "components/nav-bar/nav-bar.scss";
@import "components/button/button.scss";
@import "components/inputs/text-input/text-input.scss";
@import "components/sso/sso.scss";
@import "components/error/error.scss";
@import "components/inputs/dropdown/dropdown.scss";
@import "components/carousel/carousel.scss";
@import "components/category-picker/category-picker.scss";
@import "components/product-card/product-card.scss";
@import "components/product-card-home/product-card.scss";
@import "components/quantity-picker/quantity-picker.scss";
@import "components/breadcrumb/breadcrumb.scss";
@import "components/circle-avatar/circle-avatar.scss";
@import "components/rating-picker/rating-picker.scss";
@import "components/share-button/share-button.scss";
@import "components/short-duration-modal/short-duration-modal.scss";
@import "components/tabs/tabs.scss";
@import "components/footer/footer.scss";

/* Pages */
@import "pages/auth/login/login-page.scss";
@import "pages/auth/micro-login/micro-login-page.scss";
@import "pages/auth/forgot-password/forgot-password-page.scss";
@import "pages/auth/forgot-password-verification/forgot-password-verification-page.scss";
@import "pages/auth/reset-password/reset-password-page.scss";
@import "pages/auth/register/register-page.scss";
@import "pages/auth/vefication-account/vefication-account-page.scss";
@import "pages/common/not-found/not-found-page.scss";
@import "pages/common/mobile-user/mobile-user-page.scss";
@import "pages/common/policy/policy-page.scss";
@import "pages/common/terms/terms-page.scss";
@import "pages/common/about-us/about-us-page.scss";
@import "pages/common/help/help-page.scss";
@import "pages/common/faq/faq-page.scss";
@import "pages/home/home-page.scss";
@import "pages/category/category-page.scss";
@import "pages/merchant/merchant-page.scss";
@import "pages/product-details/product-details-page.scss";
@import "pages/accounts/account/account-page.scss";
@import "pages/accounts/account-settings/account-settings-page.scss";
@import "pages/accounts/account-profile/account-profile-page.scss";
@import "pages/accounts/account-reset-password/account-reset-password-page.scss";
@import "pages/accounts/account-address/account-address-page.scss";
@import "pages/accounts/account-wishlist/account-wishlist-page.scss";
@import "pages/accounts/account-recent-view/account-recent-view-page.scss";
@import "pages/accounts/account-orders/account-orders-page.scss";
@import "pages/accounts/account-reviews/account-reviews-page.scss";
@import "pages/notifications/notifications-page.scss";
@import "pages/cart/cart/cart-page.scss";
@import "pages/cart/checkout/checkout-page.scss";
@import "pages/cart/micro-address-form/micro-address-form-page.scss";
@import "pages/live-stream/live-stream/live-stream-page.scss";
@import "pages/live-stream/streaming/streaming-page.scss";
@import "pages/accounts/account-order-details/account-order-details-page.scss";

* {
    box-sizing: border-box;
}

html {
    padding: 0;
    margin: 0;
    height: 100%;

    body {
        padding: 0;
        margin: 0;
        min-height: 100%;
        font-family: "Roboto", sans-serif;
        white-space: pre-wrap;
        background-color: $c-layout-bg;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
