.product {
    .container {
        padding: 25px;
        flex-direction: column;

        &--no-padding {
            padding: 0;
        }
    }

    section {
        padding: 25px;
        width: calc(100% - 400px);

        &:first-child {
            width: 400px;
        }
    }

    &__carousel-section {
        min-width: 400px;
    }

    &__card {
        width: 100%;
        margin-bottom: 20px;
        padding: 35px;
        display: flex;

        &--column {
            flex-direction: column;
        }
    }

    &__carousel {
        width: 100%;
        height: 400px;

        .slide {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba($c-black, 0.2);
        }

        .control-dots {
            text-align: left;
            .dot {
                box-shadow: none;
                background-color: rgba($c-black, 0.2);
                &.selected {
                    background-color: $c-gold;
                }
            }
        }
    }

    &__carousel-item {
        width: 100%;
        height: 400px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    &__section {
        display: flex;

        .product__header-txt {
            margin: 0 0 10px;
        }

        &--divider {
            padding-bottom: 35px;
            margin-bottom: 35px;
            border-bottom: 1px solid $c-border;
        }

        &--column {
            flex-direction: column;
        }

        &--justify-between {
            justify-content: space-between;
        }

        &--footer {
            button {
                margin-top: 10px;
                margin-right: 10px;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        button {
            margin: 0 10px;
        }

        &--action {
            display: flex;
            align-items: flex-start;

            svg {
                &:first-child {
                    margin-right: 10px;
                }
            }

            .product__heart {
                color: rgba($c-black, 0.2);

                &--active {
                    color: $c-danger;
                }
            }
        }
    }

    &__rating {
        display: flex;
        align-items: center;

        &--reviews {
            padding: 15px 0;
            border-bottom: 1px solid $c-border;
        }
    }

    &__txt {
        &--label {
            padding-top: 20px;
            margin-bottom: 5px;
            color: rgba($c-secondary, 0.5);
        }
    }

    &__vars {
        //
    }

    &__vars-wrapper {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
    }

    &__var-item {
        cursor: pointer;
        margin: 0 10px 10px 0;
        padding: 5px 20px;
        background-color: $c-variations;

        &--active {
            background-color: $c-variations-active;
            border: 1px solid $c-variations-active-border;

            p {
                font-weight: 500;
                color: $c-variations-active-border;
            }
        }

        &--disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__quantity-ctrl {
        width: 100%;
        display: flex;
        align-items: center;
    }

    &__quantity-wrapper {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__quantity-btn {
        width: 35px;
        height: 35px;
        margin: 0 15px;
        color: $c-white;
        font-size: 18px;
        border-radius: 40px;
        border: 0;
        cursor: pointer;
        background-color: $c-dark-gold;
        display: flex;
        justify-content: center;
        align-items: center;

        &--outline {
            border: 1px solid $c-dark-gold;
            background-color: $c-white;

            svg {
                color: $c-dark-gold;
            }
        }

        &:disabled {
            opacity: 0.5;
            color: $c-white;
            border: 1px solid $c-disabled;
            background-color: $c-disabled;

            svg {
                color: $c-white;
            }
        }
    }

    &__quantity-input {
        padding: 5px;
        width: 80px;
        outline: none;
        border-radius: 5px;
        text-align: center;
        border: 1px solid rgba($c-black, 0.2);
    }

    &__card-header {
        margin: 20px 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__card-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-items: center;
    }

    &__company {
        width: 100%;
    }

    &__company-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__company-wrapper {
        margin-top: 10px;
        display: flex;
        align-items: center;

        p {
            margin-right: 15px;
        }
    }

    &__company-image {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        margin-right: 15px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
    }

    &__reviews {
        margin-top: 25px;

        &--seller {
            margin-top: 10px;
            margin-left: 25px;
            padding: 20px;
            display: inline-block;
            border-radius: 8px;
            background-color: rgba($c-secondary, 0.1);
        }
    }

    &__reviews-content {
        display: flex;
        align-items: center;
    }

    &__reviews-user {
        margin-left: 5px;

        p {
            margin-bottom: 5px;
            margin-left: 10px;
        }
    }

    &__reviews-comment {
        margin: 15px 0;
    }

    &__reviews-image {
        width: 150px;
        height: 150px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__reviews-replies {
        display: flex;
        align-items: center;
        margin-top: 10px;

        p {
            margin-left: 10px;
        }
    }
}

.slider:before {
    background-color: transparent !important;
}

$Iconpath: '../../assets/icons/'; //My image icon's path

.control-prev:before {
    border-right: none !important;
    content: url($Iconpath + 'backArrow.svg') !important;
}
[dir='rtl'] .control-prev:before {
    content: url($Iconpath + 'backArrow.svg') !important;
}

.control-next:before {
    top: auto !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border-left: none !important;
    content: url($Iconpath + 'arrow.svg') !important;
}
[dir='rtl'] .control-next:before {
    top: auto !important;
    box-shadow: none !important;
    background-color: transparent !important;
    content: url($Iconpath + 'arrow.svg') !important;
}

.carousel.carousel-slider .control-arrow {
    top: auto !important;
    box-shadow: none !important;
    background-color: transparent !important;
}

.carousel .control-disabled.control-arrow {
    opacity: 0.1 !important;
    display: inherit !important;
}

.carousel .control-prev.control-arrow {
    margin-left: 80% !important;
}

.product__carousel .control-dots .dot.selected{
    background-color: #C2A161;
}

.carousel .slide{
    background-color: white !important;
}

.carousel .slider{
    border: none;
}

.notification-enter {
    transform: translate3d(0, 0, 0) !important;
  }
  
.notification-leave.notification-leave-active {
    transform: translate3d(0, 0, 0) !important;
  }
.notification-container{
    left: 50% !important;
    transform: translate(-50%, 0) !important;
}