.checkout {
    padding: 25px;

    &__shipping-line {
        width: 100%;
        height: 5px;
        margin: 60px 0;
        background-size: contain;
        background-repeat: repeat-x;
        background-image: url(../../../assets/border_shipping.png);
    }

    &__shipping-details {
        padding: 0 35px;

        &:first-child {
            margin-bottom: 40px;
            border-bottom: 1px solid rgba($c-black, 0.1);
        }
    }

    &__shopName {
        padding: 15px;
        background-color: #555555;
    }

    &__shipping-content {
        margin: 20px 0;
        display: flex;

        &--header {
            align-items: center;
            justify-content: space-between;
        }

        svg {
            margin-right: 25px;
            color: rgba($c-black, 0.2);
        }

        p {
            margin-bottom: 5px;
        }
    }

    &__edit {
        display: flex;
        align-items: center;
        cursor: pointer;

        p {
            margin: 0;
        }

        svg {
            margin-right: 5px;
            color: $c-dark-gold;
        }
    }

    &__item {
        margin: 0 0 2px;
        padding: 20px;
        background-color: $c-white;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
    }

    &__item-wrapper {
        width: 20%;
        display: flex;
        align-items: center;

        &--product {
            width: 50%;
        }

        &--total {
            justify-content: flex-end;
        }
    }

    &__item-product {
        margin-left: 10px;

        p {
            margin: 5px;
        }
    }

    &__total {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--white {
            padding: 10px 20px;
            background-color: $c-white;
        }
    }

    &__subtotal-price {
        padding: 10px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        span {
            width: 300px;
            margin-left: 15px;
            font-weight: 400;
            text-align: right;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        p {
            margin: 2.5px 0;
            font-weight: 400;
            display: flex;
            align-items: center;

            &:not(:nth-child(1)) {
                margin-right: 20px;
            }
        }
    }

    &__courier-button {
        cursor: pointer;
        line-height: 1;
    }

    &__icon {
    }

    &__total-price {
        p {
            margin: 10px 0;
        }
    }

    &__button-wrapper {
        margin-top: 15px;
        display: flex;

        &--center {
            justify-content: center;
        }

        button {
            margin: 10px;
        }
    }
}

.address-modal-card {
    width: 350px;
    height: 550px;
    padding: 20px;
    border-radius: 15px;
    background-color: rgba($c-black, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.errorModal > button {
    display: none;
}
