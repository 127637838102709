.button {
    width: 100%;
    height: 54px;
    max-width: 250px;
    min-width: 124px;
    border-radius: 50px;
    outline: none;
    background-color: $c-primary;
    border: 0;
    cursor: pointer;

    &--outline {
        border: 1px solid rgba($c-black, 0.5);
        background-color: $c-white;

        .button__txt {
            color: $c-black;
        }
    }

    &:disabled {
        opacity: 0.7;
    }

    &__txt {
        font-size: 16px;
        color: $c-gold;
    }

    &--txt {
        background-color: transparent;

        .button__txt {
            color: $c-black !important;
        }
    }

    &--txt-red {
        background-color: transparent;

        .button__txt {
            color: $c-danger !important;
        }
    }

    &--txt-border {
        background-color: transparent;
        border: 1px solid grey;
        .button__txt {
            color: $c-black !important;
        }
    }

    &--txt-border-gold {
        background-color: transparent;
        border: 1px solid #C19E66;
        .button__txt {
            color: #C19E66 !important;
        }
    }

    &--txt-gold {
        background-color: transparent !important;

        .button__txt {
            color: #C19E66 !important;
        }
    }

    &--gold {
        max-width: 200px;
        background-color: $c-dark-gold;

        .button__txt {
            color: $c-white;
        }
    }

    &--small {
        height: 48px;
        max-width: 190px;

        .button__txt {
            font-size: 14px;
        }
    }

    &--tiny {
        height: 36px;
        max-width: 104px;

        .button__txt {
            font-size: 14px;
        }
    }

    &--bold{
        .button__txt {
            font-weight: 700;
        }
    }
}
