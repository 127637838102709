$c-white: #ffffff;
$c-black: #000000;
$c-gold: rgba(234, 203, 101, 1);
$c-dark-gold: rgba(194, 161, 97, 1);
$c-danger: rgba(226, 43, 43, 1);
$c-valid: rgba(84, 191, 134, 1);
$c-warning: rgba(255, 193, 7, 1);
$c-primary: rgba(28, 28, 28, 1);
$c-secondary: rgba(85, 85, 85, 1);
$c-disabled: rgba(102, 102, 102, 1);
$c-layout-bg: #f9f9f9;
$c-border: #efefef;
$s-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
$c-input-bg: rgba(242, 242, 242, 1);
$c-input-placeholder: rgba(77, 77, 77, 1);
$c-variations: rgba(243, 243, 246, 1);
$c-variations-active: #fff2da;
$c-variations-text: rgba(102, 102, 102, 1);
$c-variations-active-border: rgba(193, 158, 102, 1);
