.account-recent-view {
    padding: 25px;
    
    &__wrapper {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}
