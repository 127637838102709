.cart {
    padding: 25px;

    &__card-header {
        margin-top: 15px;
        padding: 20px;
        color: $c-white;
        font-size: 18px;
        background-color: $c-secondary;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .m-txt {
            color: $c-white;
        }
    }

    &__link {
        color: $c-white;
        text-decoration: none;
        display: flex;
        align-items: center;

        .m-txt {
            margin-right: 5px;
        }
    }

    &__item {
        padding: 20px;
        background-color: $c-white;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        &--disabled {
            opacity: 0.5;
        }
    }

    &__item-wrapper {
        width: 20%;
        display: flex;
        align-items: center;

        &--product {
            width: 50%;
        }

        &--quantity {
            width: 30%;
        }

        &--delete {
            p {
                cursor: pointer;
                display: flex;
                align-items: center;
            }
        }

        &--total {
            justify-content: flex-end;
        }
    }

    &__item-icon {
        margin-right: 15px;
        color: rgba($c-black, 0.3);
        cursor: pointer;
        pointer-events: auto;

        &--active {
            color: $c-black;
        }
    }

    &__item-product {
        margin-left: 10px;

        p {
            margin: 5px;
        }
    }

    &__total {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--white {
            padding: 10px 20px;
            background-color: $c-white;
        }
    }

    &__total-price {
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 10px 0;
      
            &:first-child {
                width: 100px;
                text-align: right;
            }
        }
    }

    &__confirm-modal {
        .cart__card {
            width: 350px;
            height: 200px;
            padding: 20px;
            border-radius: 15px;
            background-color: rgba($c-black, 0.8);
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
        }
    }

    &__modal-label {
        color: $c-white;
        font-size: 18px;
        text-align: center;
    }

    &__button-wrapper {
        margin-top: 15px;
        display: flex;

        &--center {
            justify-content: center;
        }

        button {
            margin: 10px;
        }
    }
}
