.streaming {
    padding: 25px;

    &__container {
        align-items: flex-start;
    }

    &__wrapper {
        display: flex;
    }

    &__section {
        width: 70%;
        display: flex;
        flex-direction: row;

        &:first-child {
            margin-right: 15px;
        }

        &--chat {
            position: relative;
            overflow: hidden;
            background-color: $c-black;

            .rc-scrollbars-container {
                height: calc(100% - 50px) !important;
            }
        }

        &--product {
            width: 100%;
            margin-top: 15px;
        }
    }

    &__count {
        position: absolute;
        padding: 10px;
        right: 0;
        display: flex;
        align-items: center;
    }

    &__count-wrapper {
        margin: 10px;
        display: flex;
        align-items: center;
        opacity: 0.8;
    }

    &__icon {
        margin-right: 10px;
        color: $c-white;

        &--liked {
            color: $c-danger;
        }
    }

    &__video {
        width: 100%;
        min-width: 450px;        
        border-radius: 10px;
        overflow: hidden;
        background-color: rgba($c-black, 0.1);

        video {
            width: 100%;
            min-height: 600px;
            object-fit: cover;
        }

        iframe {
            width: 100%;
            min-height: 600px;
        }
    }

    &__chat-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__chat-bubble {
        display: flex;
        margin: 0 0 12px;
        animation: slideLeftIn 0.5s ease forwards;
    }

    &__chat-input {
        padding: 20px 10px;
        z-index: 999;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: transparent;

        form {
            display: flex;
            align-items: center;

            input {
                height: 40px;
                width: 70%;
                border: 0;
                outline: 0;
                color: $c-white;
                background-color: transparent;
                border: 1px solid white;
                border-radius: 5px;
                &::placeholder {
                    color: rgba($c-white, 0.8);
                }
            }

            button {
                margin: 0 15px;
                border: 0;
                color: $c-white;
                cursor: pointer;
                background-color: transparent;
            }

            .streaming__icon {
                cursor: pointer;

                &:hover {
                    color: $c-danger;
                }
            }
        }
    }

    &__chat-bubble-content {
        min-width: 100px;
        max-width: 90%;
        margin-left: 10px;
        padding: 3px;
        border-radius: 5px;
        word-break: break-word;

        p {
            margin: 0 0 2.5px;
        }

        &--joined {
            background-color: #C19E66;
            padding: 5px 10px;

            p {
                margin: 0;
                font-size: 12px;
            }
        }
    }

    &__product-wrapper {
        width: 100%;
        padding: 5px;
    }

    &__product-header {
        padding: 15px 0;
        border-bottom: 1px solid $c-border;
    }

    &__product {
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
            border-bottom: 1px solid $c-border;
        }
    }

    &__product-content {
        display: flex;
        align-items: center;
        flex-direction: revert;
        justify-content: space-evenly;

        .circle-avatar {
            margin-right: 15px;
        }

        p {
            margin: 5px 0;
        }
    }

    &__product-add {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        background-color: $c-valid;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        a {
            line-height: 0;
        }

        svg {
            color: $c-white;
        }
    }
}

.product_card{    
    width: 30%;
    box-shadow: $s-shadow;
    padding: 1.5rem;
}

.product_card > p{
    font-size: 18px;
    font-weight: 500;
}