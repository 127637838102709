.micro-login {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 550px;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 9999999999;

    &--slide-in {
        animation: slideIn 0.5s ease forwards;
    }

    &--slide-out {
        animation: slideOut 0.5s ease forwards;
    }
}
