.account {
    padding: 25px;

    .container {
        flex-direction: column;
    }

    &__wrapper {
        margin: 50px;
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &__name {
        margin-left: 25px;
        font-size: 28px;
        font-weight: 300;
        color: $c-black;
    }

    &__body {
        margin-top: 35px;
    }

    &__card {
        width: 188px;
        height: 188px;
        margin-top: 20px;
        margin-right: 20px;
        padding: 20px;
        border-radius: 8px;
        cursor: pointer;
        background-color: $c-white;
        border: 1px solid rgba($c-black, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            margin-bottom: 20px;
        }
    }

    &__card-items {
        width: 100%;
        display: flex;
        align-items: center;
    }

    &__card-wrapper {
        position: relative;
    }

    &__badge {
        position: absolute;
        top: -8px;
        right: -8px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        color: $c-white;
        border-radius: 20px;
        background-color: $c-danger;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__footer {
        margin-top: 50px;
        display: flex;
        flex-direction: column;

        a {
            margin: 10px 0;
        }

        .share-button {
            button {
                padding: 0;
                margin-top: 10px;
                font-size: 16px;
                text-decoration: underline;
            }
        }
    }
}

.acc-bar-width{
    width: 812px;
}