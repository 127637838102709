.micro-address {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    visibility: hidden;
    transform: translateX(100%);
    z-index: 9999999999;

    &--slide-in {
        animation: slideIn 0.5s ease forwards;
    }

    &--slide-out {
        animation: slideOut 0.5s ease forwards;
    }

    &__header {
        padding: 20px;
    }

    &__card {
        width: 100%;
        height: calc(100% - 40px);
        padding: 10px 10px 60px;
        margin: 20px;
        overflow: hidden;
    }

    &__form {
        padding: 20px;
        margin: 10px;
    }

    &__header {
        display: flex;
        align-items: center;

        p {
            margin-left: 15px;
        }
    }

    &__input-wrapper {
        margin-top: 30px;

        &:first-child {
            margin: 0;
        }
    }

    &__button-wrapper {
        margin-top: 35px;
    }
}
