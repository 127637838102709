.account-profile {
    padding: 50px;
    width: 100%;

    &__header {
        display: flex;
        align-items: center;

        p {
            margin-left: 15px;
        }
    }

    &__input-wrapper {
        margin-top: 30px;
    }

    &__button-wrapper {
        margin-top: 35px;
    }
}
