.account-orders {
    padding: 25px;

    &__wrapper {
    }

    &__header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $c-border;
    }

    &__card-wrapper {
        padding: 15px;
        margin: 15px 0;
        max-width: 600px;

        &--item {
            display: flex;
            align-items: center;
        }
    }

    &__card-content {
        padding: 15px 0;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__item {
        margin-left: 10px;
    }

    &__label {
        margin: 8px 0 0;
    }

    &__status {
        p {
            padding: 8px 15px;
            color: $c-white;
            border-radius: 5px;
            background-color: rgba($c-dark-gold, 0.9);
        }
    }
}

.myOrderTabs{
    padding: 0px !important;
    text-transform: inherit !important;
}

.myOrderTabs > span{
    border-left: 1px solid lightgrey;
}

.singleOrderTab > div {
    padding: 0px !important;
}

.generalOrderCardHeader{
    background-color: #E6E6E6;
}

.generalOrderCardHeader > div {
    padding: 15px 30px !important;
}

.generalOrderCardContent{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 45px 35px;
}

.generalOrderCardContentButton{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px 35px 15px;
}

.generalOrderCardContent_review{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 45px 15px;
}