.merchant {
    padding: 10px;

    &__wrapper {
        margin-top: 15px;
        padding: 35px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &:last-child {
            padding-top: 0;
        }
    }

    &__header {
        margin-left: 15px;
        
        p {
            margin-bottom: 7px;
        }
    }
}
