.share-button {
    button {
        border: 0;
        cursor: pointer;
        background-color: transparent;
        display: flex;
        align-items: center;
    }

    &__icon {
        &--white {
            color: $c-white;
        }
    }
}

.share-modal {
    width: 400px;
    margin: 0 auto;
}

.share-card {
    position: relative;
    width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: $s-shadow;

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        border: 0;
        cursor: pointer;
        background-color: transparent;
    }

    button {
        outline: none;
        margin: 10px 5px;
    }

    p {
        margin: 10px;
    }
}

.speech {
    width: 300px;
    margin: 15px auto;
    padding: 20px;
    text-align: center;
    position: relative;
}

/* speech bubble 10 */

.upperArrow:before {
    content:"";
  position: absolute;
  top:-7px;
  left:275px;
  margin:auto;
  height: 15px;
  width: 15px;
  border:1px solid #fff;
  transform:rotate(135deg);
  background-color: #fff;
  border-bottom:1px solid #f3f3f3f2;
  border-left:1px solid #f3f3f3;
  }
  
  
  
  .share_separator {
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .share_separator::before,
  .share_separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #E6E6E6;
  }
  
  .share_separator:not(:empty)::before {
    margin-right: .25em;
  }
  
  .share_separator:not(:empty)::after {
    margin-left: .25em;
  }