.live-stream {
    padding: 25px;

    &__wrapper {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-items: center;

        &--center {
            text-align: center;
        }
    }

    &__card {
        margin: 15px 15px 10px 15px;
        height: 160px;
        border-radius: 8px;
        cursor: pointer;
        overflow: hidden;
        box-shadow: $s-shadow;
    }

    &__image {
        height: 160px;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__content {
        padding: 10px 15px;
        display: flex;

        &--footer {
            justify-content: space-between;
        }
    }

    &__content-wrapper {
        margin-left: 10px;

        p {
            margin-bottom: 5px;
        }

        &--action {
            margin-left: 0;
            color: $c-white;
            display: flex;
            align-items: flex-end;
            
            p {
                margin: 0 5px;
            }
        }
    }

    &__heart {
        color: rgba($c-white, 0.5);

        &--active {
            color: $c-danger;
        }
    }
}