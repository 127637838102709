.dropdown {
    &__container {
        position: relative;
        margin-bottom: 15px;
    }

    &__label {
        margin-bottom: 5px;
    }

    &__menu {
        width: 100%;
        max-width: 400px;
        max-height: 350px;
        padding: 10px;
        font-weight: 300;
        border-radius: 5px;
        background-color: $c-white;
        overflow-y: scroll;
        border: 1px solid rgba($c-black, 0.1);

        .Dropdown-option {
            padding: 15px;
            margin: 5px 0;
            &.is-selected {
                border-radius: 5px;
                background-color: $c-input-bg;
            }

            &:hover {
                border-radius: 5px;
                background-color: $c-input-bg;
            }
        }
    }

    &__field {
        width: 100%;
        height: 58px;
        max-width: 400px;
        padding: 16px;
        font-weight: 400;
        border-radius: 5px;
        background-color: $c-input-bg;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .Dropdown-placeholder {
            opacity: 0.5;
            color: $c-input-placeholder;
        }

        &--inserted {
            .Dropdown-placeholder {
                opacity: 1;
                color: $c-black;
            }
        }

        &--outline {
            background-color: $c-white;
            border: 1px solid rgba($c-black, 0.1);
        }

        &--max {
            max-width: 100%;
        }

        &::placeholder {
            color: $c-input-placeholder;
        }
    }
}
