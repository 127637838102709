$navHeight: 87px;

.m-app {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.m-page {
    position: relative;
    height: calc(100% - #{$navHeight});
}

.m-full-h {
    height: 100%;
}

.m-container {
    width: 100%;
    height: 100%;
    max-width: 1920px;
    margin: 0 auto;

    &--center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--wrap {
        flex-wrap: wrap;
    }

    @media screen and (max-width: 1366px) {
        max-width: 1366px;
    }
}

.m-wrapper {
    width: 100%;
    max-width: 1366px;
}

.m-sticky-header {
    position: fixed !important;
    height: 120px;
    width: 100%;
    top: 0;
    z-index: 99999;
}

.m-card {
    max-width: 1366px;
    border-radius: 10px;
    background-color: $c-white;

    &--shadow {
        box-shadow: $s-shadow;
    }
}

.m-txt {
    margin: 0;
    font-weight: 300;
    color: $c-black;

    &--xs {
        font-size: 12px;
    }

    &--s {
        font-size: 14px;
    }

    &--m {
        font-size: 20px;
    }

    &--l {
        font-size: 25px;
    }

    &--xl {
        font-size: 30px;
    }

    &--light {
        font-weight: 100;
    }

    &--bold {
        font-weight: 500;
    }

    &--error {
        color: $c-danger;
    }

    &--valid {
        color: $c-valid;
    }

    &--warning {
        color: $c-warning;
    }

    &--grey {
        color: rgba($c-black, 0.5);
    }

    &--gold {
        color: $c-gold;
    }

    &--white {
        color: $c-white;
    }
}

.m-link {
    @extend .m-txt;
    @extend .m-txt--s;
    text-decoration: underline;
}

.m-headline {
    margin: 0;
    font-size: 25px;
    font-weight: 400;
    color: $c-black;
}

.m-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba($c-black, 0.5);
    z-index: 999999999;

    &--fade-in {
        animation: fadeIn 0.2s ease-in forwards;
    }

    &--fade-out {
        animation: fadeOut 0.2s ease-in forwards;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        visibility: visible;
    }
    99% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(100%);
        visibility: visible;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
        visibility: visible;
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
        visibility: visible;
    }
    99% {
        visibility: visible;
        transform: translateX(100%);
    }
    100% {
        visibility: hidden;
    }
}

@keyframes slideLeftIn {
    0% {
        opacity: 0;
        transform: translateX(-100%);
        visibility: visible;
    }
    100% {
        opacity: 1;
        transform: translateX(0);
        visibility: visible;
    }
}