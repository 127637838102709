.notifications {
    padding: 25px;

    &__wrapper {
        margin-bottom: 20px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $c-border;
    }

    &__card-wrapper {
        padding: 15px;
        margin: 10px 0;
        max-width: 600px;

        &--item {
            display: flex;
            align-items: center;
        }
    }

    &__card-content {
        padding: 15px 0;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__item {
        margin-left: 10px;

        .m-txt {
            p {
                margin: 5px 0;
            }
        }
    }

    &__label {
        margin: 8px 0 0;
    }

    &__status {
        padding: 8px 15px;
        border-radius: 5px;

        p {
            color: $c-white;
        }

        &--green {
            background-color: $c-valid;
        }

        &--orange {
            background-color: $c-warning;
        }

        &--red {
            background-color: $c-danger;
        }
    }
}
