.category {
    &__wrapper {
        display: flex;
    }

    &__products-wrapper {
        padding: 0 10px;
        width: calc(100% - 350px);
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    &__filter {
        width: 350px;
        padding: 20px;
        background-color: $c-white;
    }

    &__headline {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($c-black, 0.2);
    }

    &__price-spacer {
        margin: 0 5px;
    }

    &__filter-section {
        margin: 15px 0 25px;

        .text-input__container {
            margin: 0;
        }

        .button {
            margin: 7px 7px 7px 0;
        }
    }

    &__input-wrapper {
        display: flex;
        align-items: center;
    }

    &__label {
        margin-bottom: 10px;
    }

    &__filter-btn {
        position: relative;
        padding: 8px 25px;
        margin: 5px;
        font-size: 14px;
        color: $c-secondary;
        border: 0;
        border-radius: 100px;
        background-color: rgba($c-secondary, 0.1);

        &--active {
            color: $c-white;
            background-color: $c-dark-gold;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: -5px;
                right: -10px;
                height: 25px;
                width: 25px;
                border-radius: 50px;
                background-color: $c-valid;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 18;
                background-image: url(../../assets/icons/checked.svg);
            }
        }

        &:hover {
            color: $c-white;
            background-color: $c-dark-gold;
        }
    }
}
