.m-carousel {
    width: 100%;

    .slide {
        background-color: $c-primary;
    }

    &__slider {
        width: 100%;
        height: 450px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}
