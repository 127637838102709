.mobile-user {
    .container {
        flex-direction: row;
        align-items: stretch;
        text-align: center;
    }

    &__headline {
        max-width: 640px;
        margin: 35px 0;
    }

    &__image {
        width: 100%;
        max-width: 350px;
    }

    &__card {
        width: 100%;
        min-height: 550px;
        margin: 35px;
        padding: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__apps {
        a {
            margin: 10px;
        }
    }
}
