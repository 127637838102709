.rating {
    display: flex;
    align-items: center;

    &__star {
        margin-left: 8px;
        color: rgba($c-black, 0.2);
        cursor: pointer;

        &--active {
            color: $c-gold;
        }
    }

    p {
        margin-left: 10px;
    }
}
