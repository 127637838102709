.account-reviews {
    padding: 25px;

    &__table {
        width: 100%;
        margin-top: 20px;
        background-color: $c-white;

        th {
            padding: 20px;
            color: $c-white;
            font-weight: 400;
            background-color: $c-secondary;
            border: 1px solid rgba($c-white, 0.2);
        }

        td {
            padding: 5px 20px;
            font-weight: 400;
            border: 1px solid $c-border;
        }
    }

    &__td {
        display: flex;
        align-items: center;
    }

    &__center {
        display: flex;
        justify-content: center;
    }

    &__comments {
        margin-left: 10px;
    }

    &__image {
        width: 84px;
        height: 84px;
        margin: 0 auto;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &__form {
        padding: 20px;
    }

    &__input-wrapper {
        position: relative;
        padding: 10px 15px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        &--start {
            align-items: flex-start;
        }

        p {
            margin-top: 10px;
        }
    }

    &__textarea {
        width: 450px;
        height: 150px;
        padding: 10px;
        resize: none;
        border: 0;
        outline: none;
        border-radius: 8px;
        background-color: $c-layout-bg;
    }

    &__upload-btn {
        color: $c-gold;
        border: 0;
        background-color: transparent;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
            margin-left: 5px;
            font-size: 16px;
        }
    }

    &__button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            margin: 10px;
            width: auto;
        }
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 10px;
        border: 0;
        cursor: pointer;
        background-color: transparent;
    }
}
