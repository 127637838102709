.cancelBar{
    border: 1px solid #DA5050;
    width: 40%;
    border-radius: 5px;
    padding: 12px 25px;
    background-color: #FFEDED;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    font-weight: 500;
    color: #DA5050;
}

.defaultBar{
    border: 1px solid #C19E66;
    width: 40%;
    border-radius: 5px;
    padding: 12px 25px;
    background-color: #FFFCF3;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    font-weight: 500;
    color: #C19E66;
}