.home {
    &__banners {
        min-height: 450px;
        background-color: $c-secondary;
    }

    &__categories {
        position: relative;

        .m-wrapper {
            position: absolute;
        }
    }

    &__products {
        position: relative;
        margin-top: 80px;

        &--white {
            padding: 35px 0;
            background-color: $c-white;
        }
    }

    &__header {
        margin: 20px 20px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__card-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-items: center;
    }
}
